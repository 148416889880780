import { useQuery } from "react-query";
import { useContext } from "react";
import { UserContext } from "controller/context/userContext";
import { getFAQs, getOneFAQ } from "controller/services/FAQServices";
import {
  getOnePopularRoute,
  getPopularRoutes,
} from "controller/services/popularRouteServices";
import { getAppSettings } from "controller/services/maintenanceServices";
import {
  getCustomers,
  getOneCustomer,
  getOneUser,
  getUsers,
} from "controller/services/userServices";
import { getCms, getOneCms } from "controller/services/CMSServices";

export const useFAQ = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["FAQsData", user, page, rowsPerPage], () =>
    getFAQs({ page, rowsPerPage })
  );
};

export const useOneFAQ = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneFAQ", user, { id }], () => getOneFAQ({ id }));
};

export const usePopularRoute = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["popularRoute", user, page, rowsPerPage], () =>
    getPopularRoutes({ page, rowsPerPage })
  );
};

export const useOnePopularRoute = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["onePopularRoute", user, { id }], () =>
    getOnePopularRoute({ id })
  );
};

export const useAppSettings = ({ type }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["appSettings", user, { type }], () =>
    getAppSettings({ type })
  );
};

//users
export const useUsersList = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["usersData", user, page, rowsPerPage], () =>
    getUsers({ page, rowsPerPage })
  );
};

export const useOneUser = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneUser", user, { id }], () => getOneUser({ id }));
};

export const useCustomers = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["customerData", user, page, rowsPerPage], () =>
    getCustomers({ page, rowsPerPage })
  );
};

export const useOneCustomer = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneCustomer", user, { id }], () => getOneCustomer({ id }));
};

export const useCms = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["cmsData", user, page, rowsPerPage], () =>
    getCms({ page, rowsPerPage })
  );
};

export const useOneCms = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["onePoster", user, { id }], () => getOneCms({ id }));
};
