import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";

import MaintenanceMode from "views/admin/maintenance";

import FAQs from "views/admin/FAQ";
import AddFAQ from "views/admin/FAQ/addFAQ";
import FAQDetails from "views/admin/FAQ/FAQDetails";
import Users from "views/admin/users";
import AddUsers from "views/admin/users/addUsers";
import CMS from "views/admin/CMS";
import AddCMS from "views/admin/CMS/addCMS";
import CMSDetails from "views/admin/CMS/CMSDetails";
import PopularRoutes from "views/admin/popularRoutes";
import AddRoute from "views/admin/popularRoutes/addRoute";
import RouteDetails from "views/admin/popularRoutes/RouteDetails";
import ComingSoon from "components/comingSoon";
import AddRoles from "views/admin/roles/addRoles";

// Auth Imports
import SignIn from "views/auth/SignIn";
import TwoStepAuth from "views/auth/TwoStepAuth";
import SignUp from "views/auth/SignUp";
import ForgotPassword from "views/auth/ForgotPassword";
import ConformPassword from "views/auth/ConfirmPassword";
import Customers from "views/admin/customers";
import CustomerDetails from "views/admin/customers/customerDetails";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    component: <MainDashboard />,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    component: <SignIn />,
  },
  {
    name: "Two Step Auth",
    layout: "/auth",
    path: "two-step-auth",
    component: <TwoStepAuth />,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "forgot-password",
    component: <ForgotPassword />,
  },
  {
    name: "Conform Password",
    layout: "/auth",
    path: "conform-password",
    component: <ConformPassword />,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    component: <SignUp />,
  },
  {
    name: "All FAQs",
    layout: "/admin",
    path: "all-faqs",
    component: <FAQs />,
  },
  {
    name: "Add FAQ",
    layout: "/admin",
    path: "add-faq",
    component: <AddFAQ />,
  },
  {
    name: "Edit FAQ",
    layout: "/admin",
    path: "edit-faq/:id",
    component: <AddFAQ />,
  },
  {
    name: "FAQ Details",
    layout: "/admin",
    path: "faq-details/:id",
    component: <FAQDetails />,
  },
  {
    name: "All CMS Pages",
    layout: "/admin",
    path: "all-cms",
    component: <CMS />,
  },
  {
    name: "Add CMS Page",
    layout: "/admin",
    path: "add-cms",
    component: <AddCMS />,
  },
  {
    name: "Edit CMS Page",
    layout: "/admin",
    path: "edit-cms/:id",
    component: <AddCMS />,
  },
  {
    name: "CMS Details",
    layout: "/admin",
    path: "cms-details/:id",
    component: <CMSDetails />,
  },
  {
    name: "All Popular Routes",
    layout: "/admin",
    path: "popular-routes",
    component: <PopularRoutes />,
  },
  {
    name: "Add Popular Route",
    layout: "/admin",
    path: "add-popular-route",
    component: <AddRoute />,
  },
  {
    name: "Edit Route",
    layout: "/admin",
    path: "edit-popular-route/:id",
    component: <AddRoute />,
  },
  {
    name: "Route Details",
    layout: "/admin",
    path: "popular-route-details/:id",
    component: <RouteDetails />,
  },

  {
    name: "Maintenance Mode",
    layout: "/admin",
    path: "maintenance-mode",
    component: <MaintenanceMode />,
  },

  {
    name: "All Users",
    layout: "/admin",
    path: "all-users",
    component: <Users />,
  },
  {
    name: "Add Users",
    layout: "/admin",
    path: "add-users",
    component: <AddUsers />,
  },
  {
    name: "Edit Users",
    layout: "/admin",
    path: "edit-users/:id",
    component: <AddUsers />,
  },
  {
    name: "All Customers",
    layout: "/admin",
    path: "all-customers",
    component: <Customers />,
  },
  {
    name: "Customer Details",
    layout: "/admin",
    path: "customer-details/:id",
    component: <CustomerDetails />,
  },
  {
    name: "Add Roles",
    layout: "/admin",
    path: "add-roles",
    component: <AddRoles />,
  },
  {
    name: "Coming Soon",
    layout: "/admin",
    path: "coming-soon",
    component: <ComingSoon />,
  },
];
export default routes;
